import { Responsive, Grid } from "semantic-ui-react";
import React, { Fragment } from "react";
import PlateDesignPreview from "@components/PlateDesignsPreview/PlateDesignsPreview";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { PlateCategoryMetaInfo, ProductModelCategoryList, CurrentPlateDesign, VehicleType, TransactionType } from "@models/ProductModels";
import { PlateDesignCard } from "@components/PlateDesignCard";
import theme from "@styles/theme";

interface Props {
  apiUrl: string
  formattedCombination: string
  plateDesignList: {
    metaInfo: PlateCategoryMetaInfo
    products: ProductModelCategoryList
  }[],
  selectedPlateDesign: {
    metaInfo: PlateCategoryMetaInfo
    products: ProductModelCategoryList
  },
  vehicleType: VehicleType
  transactionType: TransactionType
  title: string
  currentPlateDesign: CurrentPlateDesign
  handleSelectCard: (data: string) => void
  isGift?: boolean
}
class DesktopSelectStyle extends React.Component<Props>{

  render() {
    const { apiUrl, plateDesignList, formattedCombination, vehicleType, transactionType, selectedPlateDesign, handleSelectCard, title, currentPlateDesign, isGift } = this.props;
    return (
      <Fragment>

        <PlateDesignPreview
          apiUrl={apiUrl}
          productCategoryList={selectedPlateDesign.products}
          metaInfo={selectedPlateDesign.metaInfo}
          transactionType={transactionType}
          formattedCombination={formattedCombination}
          vehicleType={vehicleType}
          currentPlateDesign={currentPlateDesign}
          showBackToAllDesign={false}
          title={title}
          isGift={isGift} 
          darkTheme={true}/>

        <GenericBannerContainer
          padding={{
            mobile: {
              top: 10,
              bottom: 10
            },
            desktop: {
              top: 30,
              bottom: 30
            }
          }}
          backgroundColor='black'>
          <ResponsiveGrid>
            {
              plateDesignList.map((list, key) => {
                const selected = list.metaInfo.name === selectedPlateDesign.metaInfo.name;
                return (
                  <Grid.Column key={key}>
                    <PlateDesignCard apiUrl={apiUrl}
                      cardKey={key}
                      vehicleType={vehicleType}
                      metaInfo={list.metaInfo}
                      transactionType={this.props.transactionType}
                      formattedCombination={formattedCombination}
                      productCategoryList={list.products}
                      handleSelectCard={handleSelectCard}
                      selected={selected} />
                  </Grid.Column>
                )
              })
            }
          </ResponsiveGrid>
        </GenericBannerContainer>
      </Fragment>
    );
  }
}

const ResponsiveGrid = ({ children }: any) => {
  return (
    <Fragment>
      <Responsive maxWidth={theme.responsivePlateDesign.mobile.maxWidth} as={Grid} columns={1}>
        {children}
      </Responsive>
      <Responsive minWidth={theme.responsivePlateDesign.tablet.minWidth} maxWidth={theme.responsivePlateDesign.tablet.maxWidth} as={Grid} columns={2}>
        {children}
      </Responsive>
      <Responsive minWidth={theme.responsivePlateDesign.desktop.minWidth} maxWidth={theme.responsivePlateDesign.desktop.maxWidth} as={Grid} columns={3}>
        {children}
      </Responsive>
      <Responsive minWidth={theme.responsivePlateDesign.widescreen.minWidth} as={Grid} columns={4}>
        {children}
      </Responsive>
    </Fragment>
  )
}
export default DesktopSelectStyle;