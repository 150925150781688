import { Modal } from "semantic-ui-react";
import React, { Fragment } from "react";
import { ModalContainer, H2 } from "@styles/Global.styles";
import { MobilePlateDesignPreview } from "@components/PlateDesignsPreview";
import { PlateCategoryMetaInfo, ProductModelCategoryList, CurrentPlateDesign,VehicleType,TransactionType  } from "@models/ProductModels";
import { SlidingIcon } from "@assets/svgs";

interface Props {
    apiUrl: string
    formattedCombination: string
    plateDesignList: {
      metaInfo: PlateCategoryMetaInfo,
      products: ProductModelCategoryList
    }[],
    selectedPlateDesign:{
      metaInfo: PlateCategoryMetaInfo
      products: ProductModelCategoryList
    }
    vehicleType: VehicleType
    title: string
    transactionType: TransactionType
    currentPlateDesign: CurrentPlateDesign
    handleSelectCard: (data:string) => void
    isGift?: boolean
}

interface States{
  modalOpen: boolean
}

class MobileSelectStyle extends React.Component<Props,States>{

    state = {
        modalOpen: true
    }
    componentDidMount(){
      setTimeout(
        function(this: any) {
          if (this.state.modalOpen) {
            this.setState({ modalOpen: false });
          }
        }.bind(this),
        3000
      );
    }
    handleMobileModalClose = () =>{
        this.setState({ modalOpen: false });
    }
    render(){
        const {apiUrl,formattedCombination,plateDesignList,vehicleType, transactionType, selectedPlateDesign,currentPlateDesign,handleSelectCard,title,isGift} = this.props;
        return(
          <Fragment>
            <Modal
                basic
                centered={false}
                onClose={this.handleMobileModalClose}
                open={this.state.modalOpen}
              >
                <Modal.Content>
                  <ModalContainer>
                    <SlidingIcon />
                    <H2>Swipe to browse plate styles</H2>
                  </ModalContainer>
                </Modal.Content>
              </Modal>
                {/* Mobile Version */}
                <MobilePlateDesignPreview
                    apiUrl={apiUrl}
                    formattedCombination={formattedCombination}
                    title={title}
                    vehicleType={vehicleType}
                    transactionType={transactionType}
                    plateDesigns = {plateDesignList}
                    onSelectCard = {handleSelectCard}
                    currentPlateDesign={currentPlateDesign}
                    selectedPlateDesign = {selectedPlateDesign}
                    isGift={isGift}
                />
          </Fragment>

        )
    }
}

export default MobileSelectStyle;