import React, { Fragment, useRef } from 'react'

import {
    ImageContainer,
    PlateImageHeading,
    PlateImageTitle,
    PlateImageSize,
    PlateImageContainer,
    PlateImage,
    MobileImageContainer,
    MobilePlateImageHeading,
    MobilePlateImageContainer,
    InfoBannerPlateImage,
    ImageContainerColumn,
    MobileDetailContainer,
    SelectButton,
    Header,
    Price,
    FontColor,
    PriceDetails,
    ColorPallet,
    ColorPalletContainer,
    SelectedButton
} from './MobilePlateDesignStyleItemContainer.styles'
import { PlateColorPalette } from '@components/PlateColorPalette'
import { PlateImageModel, CurrentPlateDesign, VehicleTypesSetting, VehicleType, PlateDesignModel } from "@models/ProductModels";
import { buildPlateImageUrl, getFrontPlateWidth, getBackPlateWidth } from '@utils/Helpers';
import { Grid } from 'semantic-ui-react';
import { H2 } from '@styles/Global.styles';
import theme from '@styles/theme';
import { calculateDefaultPrice } from '@components/PlateDesignsPreview/designPreviewHelper';
import { BadgeTransactionType } from '@utils/Constant';
import { MobileCardBadge, MobileCardBadgeRed } from '@components/PlateDesignCard/PlateDesignCard.styles';

interface PlateimageComponentProps {
    apiUrl: string
    plateImageModel: PlateImageModel
}


interface MobilePlateImageModel {
    apiUrl: string
    formattedCombination: string
    vehicleType: VehicleType
    currentPlateDesign: CurrentPlateDesign
    data: any
    transactionType: any
    onSelectCard: any
    productId: any
}

export const MobilePlateDesignStyleItemContainer = ({ apiUrl, formattedCombination, vehicleType, currentPlateDesign, data, transactionType, onSelectCard, productId }: MobilePlateImageModel) => {
    const price = calculateDefaultPrice(data.metaInfo, transactionType)

    var frontPlate: PlateDesignModel = {
        bottomMessageColor: data.products.categories[0]?.products[0]?.messageColors != undefined ? data.products.categories[0]?.products[0]?.messageColors[0] : undefined,
        bottomMessageText: "",
        captionPositon: undefined,
        disableDualSizing: false,
        plateDesignCode: data.products.categories[0]?.products[0]?.id,
        plateSize: data.products.categories[0]?.products[0]?.size,
    	textColor: data.products.categories[0]?.products[0]?.variants[0]?.textColor,
        topMessageColor: data.products.categories[0]?.products[0]?.messageColors != undefined ? data.products.categories[0]?.products[0]?.messageColors[0] : undefined,
        topMessageText: ""
    };

    const frontPlateWidth = getFrontPlateWidth(currentPlateDesign.frontPlate.plateSize.width, currentPlateDesign.backPlate.plateSize.width);
    var fontColor: any = [];
    data.products.categories.map((item: any) => {
        if(!fontColor.includes(item.products[0]?.variants[0]?.textColor.color)){
            fontColor.push(item.products[0]?.variants[0]?.textColor.color)
        }
    })

    var plateColors: any = [];
    var plateColorsChecker: any = [];
    data.products.categories.map((item: any) => {
        if(!plateColorsChecker.includes(item.plateColor.color)){
            plateColors.push(item.plateColor);
            plateColorsChecker.push(item.plateColor.color)
        }
    })

    var fontColors = data.products.categories[0].products[0].variants
    var messageColors = data.products.categories[0].products[0].messageColors
    var mColor = messageColors ? messageColors[0]?.id : ''

    const frontPlateUrl = buildPlateImageUrl(apiUrl, frontPlate, formattedCombination, 750) + '?tcc='+mColor+'&tct=ENTER%20A%20MESSAGE&bcc='+mColor+'&bct=ENTER%20A%20MESSAGE';

    var metaInfo = data.metaInfo
    var badgeRaw = metaInfo.isNewDesign.split("|")
    var badge = "None"
    
    if(badgeRaw.length === 2){
      var tt = transactionType ? transactionType.code : ""

      if(badgeRaw[1] === BadgeTransactionType.New && tt === "new" ){
        badge = badgeRaw[0]
      }
      if(badgeRaw[1] === BadgeTransactionType.All){
        badge = badgeRaw[0]
      }
      if(badgeRaw[1] === BadgeTransactionType.Redesign && tt === "redesign" ){
        badge = badgeRaw[0]
      }
    }

    return (
        <div style={{position:"relative"}}>
        {badge === "New!" ? <MobileCardBadge>{badge}</MobileCardBadge> : badge != "None" && <MobileCardBadgeRed>{badge}</MobileCardBadgeRed>}
        <MobileImageContainer id={ "p" + data.products.categories[0].products[0].id }>
            <MobileDetailContainer>
                <Header>{data.metaInfo.name}</Header>
                <ColorPalletContainer>
                    <PlateColorPalette
                            colors={plateColors}
                            selectedColor={plateColors[0]}
                            card
                            handleColorChange={() => null}
                            enabled
                        />
                </ColorPalletContainer>
                <Price>
                    {fontColors.length > 1 &&
                    <FontColor>+{fontColors.length} font colours</FontColor>
                    }
                    <PriceDetails>From <span>${price}</span></PriceDetails>
                </Price>
                <MobilePlateImageContainer>
                    <PlateImage src={frontPlateUrl} centered width={frontPlateWidth} alt={`${formattedCombination} plate image`} />
                </MobilePlateImageContainer>
                {data.products.categories[0].products[0].id ===  parseInt(productId) ?
                    <span id={'csc-select-' + data.products.categories[0].products[0].id}><SelectedButton name={data.metaInfo.name.replace(' ','')} onClick={() => { onSelectCard(data.metaInfo.name, data.products.categories[0].products[0].id)} } id="csc-select" buttonType='secondary' color={theme.brand.colors.black} textColor={theme.brand.colors.white}>SELECTED</SelectedButton></span> :
                    <span id={'csc-select-' + data.products.categories[0].products[0].id}><SelectButton name={data.metaInfo.name.replace(' ','')} onClick={() => { onSelectCard(data.metaInfo.name, data.products.categories[0].products[0].id)} } id="csc-select" buttonType='secondary' color={theme.brand.colors.white} textColor={theme.brand.colors.black}>SELECT</SelectButton></span>
                }

            </MobileDetailContainer>
        </MobileImageContainer>
        </div>
    )
}
export default MobilePlateDesignStyleItemContainer


