import React, { createRef, useEffect } from "react"
import ReactSlick from "react-slick"

import { MobilePlateDesignStyleContainer } from "@components/PlateDesignStyleContainer"
import {
  MobilePlateDesignSliderContainer,
  // NextArrowIcon,
  // PrevArrowIcon,
} from "./PlateDesignsPreview.styles"
let firstClientX, clientX

const preventTouch = e => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    //e.preventDefault();
    e.returnValue = false

    return false
  }
}

const touchStart = e => {
  firstClientX = e.touches[0].clientX
}

const MobileDesignSlider = props => {
  let containerRef = createRef()

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    lazyLoad: 'progressive',
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: true,
          centerPadding: '30px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false
        }
      }
    ]
  };

  useEffect(() => {
    let currentContainerRef
    if (containerRef.current) {
      currentContainerRef = containerRef.current
      currentContainerRef.addEventListener("touchstart", touchStart)
      currentContainerRef.addEventListener("touchmove", preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("touchstart", touchStart)
        currentContainerRef.removeEventListener("touchmove", preventTouch, {
          passive: false,
        })
      }
    }
  })

  return (
    <MobilePlateDesignSliderContainer ref={containerRef}>
      <ReactSlick {...settings}>
        {props.bestSelling[0].items.map((data, index) => {
          const currentSelectedDesign =
            props.selectedDesignMetaInfoName === data.metaInfo.name
              ? props.currentPlateDesign
              : data.products.buildDefaultPlateDesignProps()

          return (
            <MobilePlateDesignStyleContainer
              apiUrl={props.apiUrl}
              key={index}
              vehicleType={props.vehicleType}
              currentPlateDesign={currentSelectedDesign}
              formattedCombination={props.formattedCombination}
              data={data}
              transactionType={props.transactionType}
              onSelectCard={props.onSelectCard}
            />
          )
        })}
      </ReactSlick>
    </MobilePlateDesignSliderContainer>
  )
}

export default MobileDesignSlider
