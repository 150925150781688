import { Grid, Modal } from "semantic-ui-react";
import React, { Fragment } from "react";
import { ModalContainer, H2 } from "@styles/Global.styles";
import { MobilePlateDesignPreview } from "@components/PlateDesignsPreview";
import { PlateCategoryMetaInfo, ProductModelCategoryList, CurrentPlateDesign,VehicleType,TransactionType  } from "@models/ProductModels";
import { SlidingIcon } from "@assets/svgs";
import NewMobilePlateDesignPreview from "@components/PlateDesignsPreviewMobile/MobilePlateDesignsPreview";
import { isMobile } from "@utils/Helpers";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import { Header } from "./SelectStyle.styles";

interface Props {
    apiUrl: string
    formattedCombination: string
    plateDesignList: {
      metaInfo: PlateCategoryMetaInfo,
      products: ProductModelCategoryList
    }[],
    selectedPlateDesign:{
      metaInfo: PlateCategoryMetaInfo
      products: ProductModelCategoryList
    }
    vehicleType: VehicleType
    title: string
    transactionType: TransactionType
    currentPlateDesign: CurrentPlateDesign
    handleSelectCard: (data:string, id:number) => void
    isGift?: boolean,
    groupList: any
    paragraph: string
    subTitle: string,
    groupName: any
}

interface States{
  modalOpen: boolean
}

class NewMobileSelectStyle extends React.Component<Props,States>{

    state = {
        modalOpen: true
    }
    componentDidMount(){
      setTimeout(
        function(this: any) {
          if (this.state.modalOpen) {
            this.setState({ modalOpen: false });
          }
        }.bind(this),
        2000
      );
    }
    handleMobileModalClose = () =>{
        this.setState({ modalOpen: false });
    }
    render(){
        const {apiUrl,formattedCombination,plateDesignList,vehicleType, transactionType, selectedPlateDesign,currentPlateDesign,handleSelectCard,title,isGift,groupList, groupName} = this.props;
        return(
          <Fragment>
                <Header>
                  <Grid.Row mobile={16} tablet={16} computer={5} >
                    <NavHeader infoTextColor="white" link={transactionType.id == 1 ? "/create" : "/restyle"} info="Step 2/3" />
                  </Grid.Row>
                </Header>
                {/* Mobile Version */}
                <NewMobilePlateDesignPreview
                    apiUrl={apiUrl}
                    formattedCombination={formattedCombination}
                    title={title}
                    vehicleType={vehicleType}
                    transactionType={transactionType}
                    plateDesigns = {plateDesignList}
                    onSelectCard = {handleSelectCard}
                    currentPlateDesign={currentPlateDesign}
                    selectedPlateDesign = {selectedPlateDesign}
                    isGift={isGift}
                    groupList={groupList}
                    paragraph={this.props.paragraph}
                    subTitle={this.props.subTitle}
                    groupName={groupName}
                />
          </Fragment>

        )
    }
}

export default NewMobileSelectStyle;