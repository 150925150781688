import React, { Fragment } from 'react'

import {
    MobileImageContainer,
    InfoBannerPlateImage,
    MobileDetailContainer,
    SelectButton,
    Header,
    Price,
    FontColor,
    PriceDetails
} from './MobilePlateDesignStyleContainer.styles'
import { PlateImageModel, CurrentPlateDesign, VehicleType } from "@models/ProductModels";
import theme from '@styles/theme';
import { calculateDefaultPrice } from '@components/PlateDesignsPreview/designPreviewHelper';

interface PlateimageComponentProps {
    apiUrl: string
    plateImageModel: PlateImageModel
}


interface MobilePlateImageModel {
    apiUrl: string
    formattedCombination: string
    vehicleType: VehicleType
    currentPlateDesign: CurrentPlateDesign
    data: any
    transactionType: any
    onSelectCard: any
}

export const MobilePlateDesignStyleContainer = ({ apiUrl, formattedCombination, vehicleType, currentPlateDesign, data, transactionType, onSelectCard }: MobilePlateImageModel) => {
    const price = calculateDefaultPrice(data.metaInfo, transactionType)
    var fontColors = data.products.categories[0].products[0].variants.length

    return (
        <MobileImageContainer>
            <InfoBannerPlateImage src={data.metaInfo.featureImageMobile} width={320}/>
            <MobileDetailContainer>
                <Header>{data.metaInfo.name}</Header>
                <Price>
                    {fontColors > 1 &&
                    <FontColor>+{fontColors || 0} font colours</FontColor>
                    }
                    <PriceDetails>From <span>${price}</span></PriceDetails>
                </Price>
                <span id={"csc-select-" + data.products.categories[0].products[0].id}><SelectButton onClick={() => { onSelectCard(data.metaInfo.name, data.products.categories[0].products[0].id) } } id="csc-select" buttonType='secondary' color={theme.brand.colors.black} textColor={theme.brand.colors.white}>SELECT</SelectButton></span>
            </MobileDetailContainer>
        </MobileImageContainer>
    )
}
export default MobilePlateDesignStyleContainer