import React from 'react'
import styled from '@emotion/styled';
import {Image, Grid, Container} from 'semantic-ui-react'
import {H6, H3} from '@styles/Global.styles';
import mq from '@styles/mq';
import theme from '@styles/theme';
import { KpButton } from '@elements/index';
export const BackLinkContainer = styled.div`
    ${mq({
        marginTop: ["20px","20px","0px"]
    })}
    margin-bottom: 40px;
`
export const BackLink = styled.span`
    color: ${theme.brand.colors.darkGrey};
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
`
export const PlateImageGridColumn = styled(Grid.Column)`
    background-color: ${theme.brand.colors.grey};
`
export const ImageContainerColumn =styled(Grid.Column)`
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
`
export const ImageContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    ${mq({
        paddingBottom: ['15px','25px','40px'],
        backgroundColor: [theme.brand.colors.grey,theme.brand.colors.grey,'transparent']
    })}
`
export const PlateImageHeading = styled.div`
    display: block;
    clear: both;
    margin-bottom: 8px;
    ${mq({
        width:['100%','100%','80%']
    })}
`

export const PlateImageTitle = styled(H6)`
    display: inline-block;
    margin-bottom: 0;
`

export const PlateImageSize = styled.span`
    ${mq({
        fontSize: ['10px','16px','16px'],
        lineHeight: ['24px','22px','22px']
    })}
    float: right;
    text-align: left;
    font-weight: bold;
    letter-spacing: -0.32px;
    color: ${theme.brand.colors.black};
    padding-left: 4px;
`
export const PlateImageContainer = styled.div`
    ${mq({
        width:['100%','100%','80%']
    })}
    margin-bottom: 30px;
`
export const PlateImage = styled(({...others})=><Image {...others} />)`
    &&&&{
        object-fit: cover;
        width: ${props=>props.width};
        height: 100%;
    }
`

export const InfoBannerPlateImage = styled(({width,...others})=><Image {...others} />)`
    &&&&{
        object-fit: cover;
        width: ${props=>props.width};
    }
`
export const MobileImageContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    height: 279px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
`
export const MobileDetailContainer = styled.div`
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 12px;
    text-align: center;
    background-color: #000000ad;
`

export const Header = styled.div`
    color: #fff;
    font-size: 16px;
    text-align: left;
    padding-bottom: 6px;
`
export const Price = styled.div`
    font-weight: 100;
    color: #fff;
`

export const FontColor = styled(({fontSize, ...rest}) => <div {...rest} />)`
    float: left;
    font-size: ${p => p.fontSize ? p.fontSize : '13px'};
    padding-bottom: 6px;
`
export const PriceDetails = styled.div`
    float: right;
    font-size: 13px;
    padding-bottom: 6px;
    span {
        font-weight: bold;
        color: #3DAE2B;
    }
`

export const SelectButton = styled(KpButton)`
    margin: auto;
    min-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: #0000 !important;
    border-color: #fff !important;
    color: #fff !important;
    font-weight: Bold !important;
    &:hover {
        border-color: ${theme.brand.colors.green} !important;
        color: ${theme.brand.colors.green} !important;
    }
`

export const MobilePlateImageHeading = styled.div`
    display: block;
    clear: both;
    margin-bottom: 8px;
    margin-top: 20px;

    &:first-of-type {
        margin-top: 0;
    }
`
export const MobilePlateImageContainer = styled.div`
`
export const MobilePreviewContainer = styled(Container)`
    padding-bottom: 60px;
`
export const MobileStyleHeading =styled(H3)`
    ${mq({
        paddingTop: ["0px","20px","0"]
    })}
    margin-bottom: 0;
`