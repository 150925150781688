import React, { createRef, useEffect } from "react"
import ReactSlick from "react-slick"

import { MobilePlateDesignImagesContainer } from "@components/PlateDesignImagesContainer"
import {
  MobilePlateDesignSliderContainer,
  NextArrowIcon,
  PrevArrowIcon,
} from "./PlateDesignsPreview.styles"
let firstClientX, clientX

const preventTouch = e => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    //e.preventDefault();
    e.returnValue = false

    return false
  }
}

const touchStart = e => {
  firstClientX = e.touches[0].clientX
}

const MobileDesignSlider = props => {
  let containerRef = createRef()
  
  const selectedPlateDesignSlideIndex = props.plateDesigns.findIndex(
    plateDesign => {
      return plateDesign.products.categories.find(category => {
        return category.products.find(product => {
          return (
            product.id === props.currentPlateDesign.backPlate.plateDesignCode ||
            product.id === props.currentPlateDesign.frontPlate.plateDesignCode
          )
        })
      })
    }
  )

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    touchMove: true,
    slidesToScroll: 1,
    initialSlide: selectedPlateDesignSlideIndex,
    nextArrow: <NextArrowIcon />,
    prevArrow: <PrevArrowIcon />,
    afterChange: index => {
      //console.log(index);
      props.getSelectedPlateDesignCategory(index)
    },
  }

  useEffect(() => {
    let currentContainerRef
    if (containerRef.current) {
      currentContainerRef = containerRef.current
      currentContainerRef.addEventListener("touchstart", touchStart)
      currentContainerRef.addEventListener("touchmove", preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("touchstart", touchStart)
        currentContainerRef.removeEventListener("touchmove", preventTouch, {
          passive: false,
        })
      }
    }
  })

  return (
    <MobilePlateDesignSliderContainer ref={containerRef}>
      <ReactSlick {...settings}>
        {props.plateDesigns.map((data, index) => {
          const currentSelectedDesign =
            props.selectedDesignMetaInfoName === data.metaInfo.name
              ? props.currentPlateDesign
              : data.products.buildDefaultPlateDesignProps()

          return (
            <MobilePlateDesignImagesContainer
              apiUrl={props.apiUrl}
              key={index}
              vehicleType={props.vehicleType}
              currentPlateDesign={currentSelectedDesign}
              formattedCombination={props.formattedCombination}
            />
          )
        })}
      </ReactSlick>
    </MobilePlateDesignSliderContainer>
  )
}

export default MobileDesignSlider